<ng-container *ngIf="isUkShfyInstance$ | async; else originalForgotPasswordPage">
  <app-dxp-send-forgot-password data-test-id="send-forgot-password-dxp-send-forgot-password" ></app-dxp-send-forgot-password>
</ng-container>

<ng-template #originalForgotPasswordPage>
  <ng-container *transloco="let t; read: 'sendForgotPassword'">
    <div data-test-id="send-forgot-password-main-container"  class="main-container">
      <div data-test-id="send-forgot-password-card-x"  class="cardx shadow-sm">
        <img data-test-id="send-forgot-password-logo" 
          class="logo"
          [src]="(isDKInstance$ | async) ? '/assets/images/new/branding-logo.png' : '/assets/img/synlab-logo.svg'"
          [alt]="(isDKInstance$ | async) ? 'Covidresults.dk logo' : 'SYNLAB logo'"
        />
        <p data-test-id="send-forgot-password-title">
          {{
            (isDKInstance$ | async)
              ? (t("heading") | textReplacement: "SYNLAB Access" : "Covidresults.dk")
              : t("heading")
          }}
        </p>
        <form data-test-id="send-forgot-password-email-entry-form"  [formGroup]="form" (submit)="onSubmit()" id="emailEntryForm">
          <div data-test-id="send-forgot-password-email-input-wrapper"  class="email-input">
            <input data-test-id="send-forgot-password-email-control" 
              formControlName="email"
              type="text"
              class="form-control-lg form-control"
              [placeholder]="t('email')"
              [ngClass]="{ 'is-invalid': form.invalid && form.touched }"
            />
            <span data-test-id="send-forgot-password-on-clear-btn"  id="clearInputTrigger" *ngIf="email.value" role="button" (click)="onClear('email')">
              <em data-test-id="send-forgot-password-on-clear-icon"  class="fa fa-times"></em>
            </span>
          </div>

          <div data-test-id="send-forgot-password-action-btn"  class="action-buttons pt-2">
            <button data-test-id="send-forgot-password-on-cancel-btn"  id="cancelButton" class="btn btn-light" type="button" (click)="onCancel()">
              {{ t("cancel") }}
            </button>
            <button data-test-id="send-forgot-password-proceed-btn"  class="btn btn-primary" type="submit" [disabled]="form.invalid || loading || submitted">
              {{ t("proceed") }}
            </button>
          </div>
        </form>
      </div>
      <div data-test-id="send-forgot-password-alert-success" 
        *ngIf="message && submitted"
        class="alert alert-success"
        [innerHTML]="t(message, { email: email.value })"
      ></div>
      <div data-test-id="send-forgot-password-alert-danger"  *ngIf="isUserDeactivated && submitted" class="alert alert-danger" [innerHTML]="deactivatedMessage"></div>
    </div>
    <div data-test-id="send-forgot-password-bg-img"  class="bg-image"></div>
  </ng-container>
</ng-template>
