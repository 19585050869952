<ng-container *transloco="let t; read: 'header'">
  <nav data-test-id="header-navigation" 
    aria-label="header-nav"
    class="plasma-header web-header navbar navbar-expand-lg navbar-light bg-light new-header border-bottom box-shadow sticky-top pl-bg-2"
    [ngClass]="{ 'white-labeled-icon': isDKInstance$ | async }"
  >
    <button data-test-id="header-navbar-toggler" 
      class="navbar-toggler pl-0"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span data-test-id="header-material-icons"  class="material-icons">menu</span>
    </button>
    <a data-test-id="header-navbar-brand" href="javascript:void(0)" (click)="navigateLink()" class="navbar-brand ml-2">
      <img data-test-id="header-navbar-logo"  [src]="logo$ | async" alt="SYNLAB's logo" class="img-fluid pl-logo-1" />
    </a>

    <div data-test-id="header-collapse-navbar"  *ngIf="!(isSHFYInstance$ | async)" class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul data-test-id="header-navbar-nav"  class="navbar-nav">
        <li data-test-id="header-nav-item"  class="nav-item mb-2">
          <!-- toggle close -->
          <button data-test-id="header-navbar-toggler" 
            class="navbar-toggler collapsed d-block d-lg-none pl-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span data-test-id="header-navbar-toggler-material-icon"  class="material-icons">close</span>
          </button>
        </li>

        <!--check result -->
        <li data-test-id="header-nav-item"  class="nav-item" *ngIf="isSuperSupportUser$ | async">
          <a
            data-test-id="header-nav-link"
            class="nav-link px-3 pl-text-color-3"
            [ngClass]="{ disabled: forceMFA$ | async }"
            [href]="checkResultLink$ | async"
          >
            <span data-test-id="header-nav-link-label" >{{ t("checkResultLabel") }}</span>
          </a>
        </li>

        <!-- plasma link -->
        <li data-test-id="header-nav-item"  class="nav-item" *ngIf="!isTara">
          <a
            data-test-id="header-nav-link"
            class="nav-link px-3 pl-text-color-3"
            [ngClass]="{
              'nav-active': (isDKInstance$ | async),
              disabled: (forceMFA$ | async) || (isSHFYInstance$ | async),
            }"
            [href]="plasmaLink$ | async"
          >
            <span data-test-id="header-nav-link-label" >{{ plasmaLabel$ | async | transloco }}</span>
          </a>
        </li>

        <!-- booking link -->
        <li data-test-id="header-nav-item"  class="nav-item" *ngIf="bookingLinkEnabled$ | async">
          <a
            data-test-id="header-nav-link"
            class="nav-link px-3 pl-text-color-3"
            [ngClass]="{ 'nav-active': (isDKInstance$ | async), disabled: forceMFA$ | async }"
            [href]="bookingLink$ | async"
          >
            <span data-test-id="header-nav-link-label" >{{ t("bookingLink") }}</span>
          </a>
        </li>

        <!-- [GenePlanet] Prevention Wellness Menu -->
        <li data-test-id="header-nav-item"  class="nav-item" *ngIf="showPreventionWellnessLink$ | async">
          <a data-test-id="header-nav-link" class="nav-link px-3 pl-text-color-3" [href]="preventionWellnessLink$ | async" target="_blank">
            <span data-test-id="header-nav-link-label" >{{ t("preventionWellnessLabel") }}</span>
          </a>
        </li>

        <!-- FAQ -->
        <li data-test-id="header-nav-item"  class="nav-item" *ngIf="isDKInstance$ | async">
          <a
            data-test-id="header-nav-link"
            class="nav-link px-3 pl-text-color-3"
            [ngClass]="{ 'nav-active': (isDKInstance$ | async) }"
            href="https://faq.covidresults.dk"
            target="_blank"
          >
            <span data-test-id="header-nav-link-label" >{{ t("faqLink") }}</span>
          </a>
        </li>

        <!-- show only in mobile view -->
        <li data-test-id="header-nav-item"  class="nav-item d-block d-lg-none">
          <span data-test-id="header-nav-ougout-btn"  class="nav-link px-3 pl-text-color-3" (click)="startSignoutMainWindow()">
            {{ t("logoutButton") }}
          </span>
        </li>
      </ul>
    </div>
    <nav data-test-id="header-nav-right"  class="my-2 my-md-0 nav-bar-right" aria-label="header-nav-right">
      <!-- language dropdown -->
      <div data-test-id="header-dropdown"  class="dropdown language-setting">
        <button data-test-id="header-btn-dropdown" 
          class="btn btn-default my-2 my-sm-0 d-flex align-items-center"
          type="button"
          id="d3"
          data-toggle="dropdown"
          aria-expanded="false"
          aria-haspopup="true"
        >
          <i data-test-id="header-btn-dropdown-icon" class="fa fa-globe" aria-hidden="true"></i>
        </button>
        <div data-test-id="header-dropdown-menu"  class="dropdown-menu bx-shdw-1" aria-labelledby="d3">
          <div data-test-id="header-arrow-up"  class="arrow-up"></div>
          <div data-test-id="header-scroll"  class="sroll">
            <ul data-test-id="header-flex-column"  class="nav flex-column">
              <li data-test-id="header-nav-item" 
                *ngFor="let c of countries"
                class="nav-item"
                [ngClass]="{ active: (activeLang$ | async) == c.countryCode }"
                (click)="changeActiveLanguage(c.countryCode)"
                [title]="c.name"
              >
                <div data-test-id="header-nav-item-flag-wrapper"  class="d-flex flex-column align-items-center">
                  <img data-test-id="header-nav-item-flag"  [src]="c.flag" alt="flag" />
                  <div data-test-id="header-nav-item-flag-name"  class="flag-name">{{ c.name }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- profile's dropdown -->
      <div data-test-id="header-profile-dropdown"  class="dropdown ml-1 profile-dropdown">
        <!-- small devices -->
        <span data-test-id="header-dropdown-toggle" 
          class="d-block d-lg-none dropdown-toggle"
          [routerLink]="['/account', 'profile']"
          routerLinkActive="router-link-active"
        >
          <img data-test-id="header-profile-info-icon"  src="/assets/images/new/personal-information.png" alt="" />
        </span>

        <!-- desktop view -->
        <span data-test-id="header-dropdown-toggle" 
          class="initial-rounded pl-bg-3 pl-text-color-5 pl-border-1 dropdown-toggle mr-2 d-none d-lg-block d-xl-block"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ initial | async }}
        </span>

        <div data-test-id="header-dropdown-menu"  class="dropdown-menu pl-box-shadow-1" aria-labelledby="dropdownMenuButton">
          <div data-test-id="header-arrow-up"  class="arrow-up"></div>

          <!-- links -->
          <a data-test-id="header-dropdown-item" class="dropdown-item f-2" (click)="startSignoutMainWindow()" href="javascript:;">
            <span data-test-id="header-signout-icon"  class="fas fa-sign-out-alt"></span>
            {{ t("logoutButton") }}
          </a>
        </div>
      </div>
    </nav>
  </nav>
</ng-container>
