import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, CSP_NONCE } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

/*Main*/
import { AppComponent } from "./app.component";

/*Module*/
import { InitComponent } from "./pages/init/init.component";

/*Services*/
import { AuthGuard } from "./services/authGuard/auth.guard";
import { ConfigService } from "./services/config/config.service";
import { AuthService } from "./services/auth/auth.service";
import { SiginCallbackComponent } from "./pages/signin-callback/signin-callback.component";
import { TokenInterceptor } from "./services/auth/token-interceptor/token-interceptor.service";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ForgotPasswordModule } from "./pages/forgot-password/forgot-password.module";
import { DeleteAccountModule } from "./pages/delete-account/delete-account.module";
import { RenewtokenComponent } from "./pages/renewtoken/renewtoken.component";
import { TranslocoRootModule } from "./transloco-root.module";
import { SharedModule } from "./modules/shared/shared.module";
import { TermsOfUseModule } from "./pages/terms-of-use/terms-of-use.module";
import { TermsOfUseMobileModule } from "./pages/terms-of-use-mobile/terms-of-use-mobile.module";
import { PrivacyPolicyModule } from "./pages/privacy-policy/privacy-policy.module";
import { StoreModule } from "@ngrx/store";
import { REDUCER_TOKEN, getReducers } from "./@store/reducers";
import { effects } from "./@store/effects";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { StoreService } from "./services/store.service";
import { TermsComponent } from "./modules/shared/terms/terms.component";
import { PrivacyPolicyFrameComponent } from "./modules/shared/privacy-policy-frame/privacy-policy-frame.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { SelfSamplingInstructionModule } from "./pages/self-sampling-instruction/self-sampling-instruction.module";
import { ParentSelfSamplingInstructionModule } from "./pages/parent-self-sampling-instruction/parent-self-sampling-instruction.module";
import { GeneralTermsModule } from "./pages/general-terms/general-terms.module";
import { InitSessionModule } from "./pages/init-session/init-session.module";
import { SendForgotPasswordModule } from "./pages/send-forgot-password/send-forgot-password.module";
import { CheckLogin } from "./services/authGuard/check.login.guard";
import { WasRedirectComponent } from "./pages/was-redirect/was-redirect.component";
import { EePrivacyTermsComponent } from "./pages/ee-privacy-terms/ee-privacy-terms.component";
import { BrowserRedirectModule } from "./plasma-ui-common/synlab-access-ui-components/browser-redirect/browser-redirect.component";
import { SynlabHeaderModule } from "./plasma-ui-common/synlab-access-ui-components/synlab-header/synlab-header.component";
import { UsertypeService } from "./plasma-ui-common/synlab-access-ui-components/core/usertype/usertype.service";
import { HeaderUsertypeService } from "src/app/pages/index/services/header-usertype.service";
import { HeaderConfigService } from "src/app/pages/index/services/header-config.service";
import { ProfileService } from "./plasma-ui-common/synlab-access-ui-components/core/profile/profile.service";
import { ConfigService as LibConfigService } from "./plasma-ui-common/synlab-access-ui-components/core";
import { LoggerService as LibLoggerService } from "./plasma-ui-common/synlab-access-ui-components/core";
import { LoggerService } from "./services/logger.service";
import { SpinnerModule } from "./plasma-ui-common/synlab-access-ui-components/spinner/spinner.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { GoogleTagManagerModule, GoogleTagManagerService } from "angular-google-tag-manager";
import { claimValues, GTM_PLACEHOLDER_VALUE } from "./appsettings";
import { HeaderVisibilityService as LibHeaderVisibilityService } from "./plasma-ui-common/synlab-access-ui-components/core/header-visibility/header-visibility.service";
import { HeaderVisibilityService } from "./pages/index/services/header-visibility.service";
import { ActivateAccountModule } from "./pages/activate-account/activate-account.module";
import { UserTypeGuard } from "./services/guards/user-type.guard";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RANDOM_NONCE } from "./plasma-ui-common/synlab-access-ui-components/core/constants";

const logr = new LoggerService(null);

@NgModule({
  declarations: [
    AppComponent,
    SiginCallbackComponent,
    InitComponent,
    RenewtokenComponent,
    TermsComponent,
    PrivacyPolicyFrameComponent,
    WasRedirectComponent,
    EePrivacyTermsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ForgotPasswordModule,
    SendForgotPasswordModule,
    ToastrModule.forRoot(),
    GeneralTermsModule,
    TermsOfUseModule,
    TermsOfUseMobileModule,
    SelfSamplingInstructionModule,
    ParentSelfSamplingInstructionModule,
    PrivacyPolicyModule,
    TranslocoRootModule,
    SharedModule,
    AppRoutingModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    PdfViewerModule,
    DeleteAccountModule,
    ActivateAccountModule,
    InitSessionModule,
    StoreModule.forRoot(REDUCER_TOKEN),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    BrowserRedirectModule,
    SynlabHeaderModule,
    SpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    GoogleTagManagerModule,
  ],
  providers: [
    {
      provide: "googleTagManagerId",
      useValue: GTM_PLACEHOLDER_VALUE,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, GoogleTagManagerService],
      useFactory: (cfg: ConfigService, gtms: GoogleTagManagerService) => () => {
        cfg.load().then((c) => {
          const enableGtms = cfg._envConfig?.EnableGoogleTag?.toLowerCase() == claimValues.TRUE;
          const tagId = cfg._envConfig?.GoogleTagId;
          logr.log({ enableGtms, tagId });
          if (enableGtms && tagId) {
            gtms.googleTagManagerId = tagId;
            gtms["config"].id = tagId;
          }
        });
      },
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => {
        return () => configService.loadEnvJson();
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    ConfigService,
    AuthGuard,
    UserTypeGuard,
    CheckLogin,
    AuthService,
    LoggerService,
    {
      provide: REDUCER_TOKEN,
      deps: [StoreService],
      useFactory: getReducers,
    },
    {
      provide: UsertypeService,
      useClass: HeaderUsertypeService,
    },
    {
      provide: LibConfigService,
      useClass: HeaderConfigService,
    },
    {
      provide: LibLoggerService,
      useClass: LoggerService,
    },
    {
      provide: ProfileService,
      useClass: HeaderUsertypeService,
    },
    {
      provide: LibHeaderVisibilityService,
      useClass: HeaderVisibilityService,
    },
    {
      provide: CSP_NONCE,
      useValue: RANDOM_NONCE,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
