import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { take, withLatestFrom } from "rxjs/operators";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { dk_text } from "src/app/models/dk_text";
import { LocalStorageKeys } from "src/app/models/storage.model";
import { PATIENT_WEB_LOCALE_STORAGE_KEY } from "src/app/plasma-ui-common/synlab-access-ui-components/core/constants";
import { AccountsService } from "src/app/services/accounts/accounts.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { ConfigService } from "src/app/services/config/config.service";
import { deleteScopeKey, generalScopeKey } from "src/app/services/i18n/i18n.service";

@Component({
  selector: "app-delete-account",
  templateUrl: "./delete-account.component.html",
  styleUrls: ["./delete-account.component.scss"],
})
export class DeleteAccountComponent implements OnInit {
  regId = "";
  seqId = "";
  public isValidDeleteAccountLink$: Observable<boolean>;
  dk_text = dk_text.delete;
  isDKInstance$: Observable<boolean> = this.store.select(StsSelectors.isDKInstance);
  isSHFYInstance$: Observable<boolean> = this.store.select(StsSelectors.isUkShfyInstance);
  useNewNav$: Observable<boolean> = this.store.pipe(select(StsSelectors.useNewNav));

  constructor(
    private actRoute: ActivatedRoute,
    private accountService: AccountsService,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    private cnfg: ConfigService,
    private store: Store<AppState>,
    private auth: AuthService
  ) {}

  ngOnInit() {
    const { id, seqId, sequenceId } = this.actRoute.snapshot.queryParams;
    this.regId = id;
    this.seqId = sequenceId || seqId;

    const params = { UserCode: this.regId, SequenceCode: this.seqId };
    this.isValidDeleteAccountLink$ = this.accountService.getDeleteValidity(params);
  }

  redirectToContactSynlab() {
    window.location.href = this.cnfg._envConfig.BaseProfileUrl;
  }

  delete() {
    const body = { UserCode: this.regId, SequenceCode: this.seqId };

    const url = this.cnfg._envConfig.BaseProfileUrl;
    const loc = window.location;
    this.accountService
      .confirmDelete(body)
      .pipe(withLatestFrom(this.isDKInstance$))
      .subscribe(
        ([res, isDKTenant]) => {
          const options = {
            closeButton: true,
            disableTimeOut: true,
            tapToDismiss: false,
          };

          const message: string = isDKTenant
            ? this.dk_text.confirmDeleteSuccessMessage
            : this.translocoService.translate("confirmDeleteSuccessMessage", {}, deleteScopeKey);
          const title: string = this.translocoService.translate("confirmDeleteSuccessTitle", {}, deleteScopeKey);
          const overrideOptions: any = isDKTenant ? options : null;

          this.toastr
            .success(message, title, overrideOptions)
            .onHidden.pipe(take(1))
            .subscribe((d) => this.auth.startSignoutMainWindow());
        },
        (e) => {
          this.toastr.error(
            e.error.ErrorMessage,
            this.translocoService.translate("errorOccuredTitle", {}, generalScopeKey)
          );
        }
      );
  }

  private clearStorageAfterSuccessfulDelete() {
    const sessionLangCode: string = sessionStorage.getItem(LocalStorageKeys.language);
    const localLangCode: string = localStorage.getItem(PATIENT_WEB_LOCALE_STORAGE_KEY);

    sessionStorage.clear();
    localStorage.clear();

    localStorage.setItem(PATIENT_WEB_LOCALE_STORAGE_KEY, localLangCode);
    sessionStorage.setItem(LocalStorageKeys.language, sessionLangCode);
  }
}
