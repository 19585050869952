import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-signin-callback",
  templateUrl: "./signin-callback.component.html",
  styleUrls: ["./signin-callback.component.scss"],
})
export class SiginCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.signinRedirectCallbackTrigger$.next(Date.now());
  }
}
