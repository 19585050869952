import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ReplaySubject } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { shareReplay, map } from "rxjs/operators";
import { Locale } from "../agreement/agreement.service";
import { FormFieldConfig } from "src/app/models/form-field-config.model";
import { FORM_CONFIG } from "src/app/form-configs";
import { FormConfigInterface } from "src/app/form-configs/interfaces/form-config.interface";
import { Country, CountryService } from "src/app/modules/shared/countries";
import { claimValues } from "src/app/appsettings";

/** list of country codes which needs to show MFA setup on account activation */
export const initialMultiFactorAuthCountries = ["fr"];

export interface IEnvJson {
  OIDC: { ClientId: string; ResponseType: string; Scope: string };
  ApplicationInsights: { InstrumentationKey: string };
  ApplicationMonitoring: { Enabled: boolean; ApplicationName: string };
}

export interface Config {
  Environment: string;
  Version: string;
  ApplicationName: string;
  BaseAuthUrl: string;
  BaseAdminUrl: string;
  BaseProfileApiUrl: string;
  BaseProfileUrl: string;
  BasePlasmaUrl: string;
  BaseUefaUrl: string;
  BaseWebreqUrl: string;
  BaseWebreqAdminUrl: string;
  BaseBookingUrl: string;
  BaseBookingAdminUrl: string;
  EeBaseBookingUrl?: string;
  BookingLinkEnabled: string;
  TwilioEnabled: string;
  BookingProfessionalUrl: string;
  WhiteLabelEnabled: string;
  WhiteLabelName: string;
  WardLimit: string;
  UseProfileAPI: string;
  EnablePreventionWellness: string;
  PetProfileEnabled: string;
  WardProfileEnabled: string;
  WardProfileEditEnabled: string;
  WardProfileAddEnabled: string;
  WardProfileDeleteEnabled: string;
  baseResultsApiUrl: string;
  AvailableLanguage: string;
  BaseBigCommerceUrl: string;
  Country: string;
  CookieBotAPIKey: string;
  CookieBotEnabled: string;
  RequireMFA: boolean;
  DefaultLanguage: string;
  eeIdleTimeOutInSeconds: number;
  EnableOpenReplay: boolean;
  OpenReplayProjectKey: string;
  OpenReplayIngestPoint: string;
  EnableGoogleTag: string;
  GoogleTagId: string;
  ShfyDxpBaseURL: string;
  ShfyRegistrationURL: string;
  ShfyCompleteProfileURL: string;
  UseNewUI: string;
  EnableCheckResults: string;
  ConsentCategoryName?: string;
  FICookieManagerId: string;
  EnableFICookieManager: string;
}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private _envConfigError: string = "Env config not loaded!";
  private _envConfig$ = new ReplaySubject<Config>(1);
  public _envConfig: Config;
  public envConfig$ = this._envConfig$.pipe(shareReplay({ refCount: false, bufferSize: 1 }));
  public envJson: IEnvJson = null;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
    private readonly countryService: CountryService
  ) {}

  public getLocales() {
    return this.http.get<Locale[]>(`${this._envConfig.BaseAuthUrl}/manage/locale`);
  }

  public getCountries(authUrl: string) {
    return this.http.get<any[]>(authUrl + "/v2/manage/accounts/dropdown-items?category=Country").pipe(
      map((countries) => {
        countries = countries.sort((a, b) => {
          if (a.text > b.text) return 1;
          if (a.text < b.text) return -1;
          return 0;
        });

        const computed = countries.map((country) => ({
          Code: country.value,
          Name: country.text,
          SmallName: country.text?.toLowerCase(),
        }));
        this.countryService.countries$.next(computed);
        this.countryService.countriesAsObj$.next(
          computed.reduce((a, v) => ({ ...a, [v.Code?.toLowerCase()]: v.Name }), {})
        );
        return computed;
      })
    );
  }

  load() {
    return this.http
      .get<Config>("/api/environment")
      .toPromise()
      .then(
        async (data) => {
          await this.getCountries(data.BaseAuthUrl).toPromise();
          const locales = await this.http.get<Locale[]>(`${data.BaseAuthUrl}/manage/locale`).toPromise();
          const AvailableLanguage = locales.map((locale) => locale.cultureCode).join(",");
          const newData: any = { ...data, AvailableLanguage };

          this._envConfig = newData;
          this._envConfig$.next(newData);
          this.store.dispatch(STSActions.loadConfigSuccess({ data: newData }));

          document.body.classList.add(`version-${newData["Version"]}`);
        },
        (error) => {
          console.log(error);
          throw error;
        }
      );
  }

  loadEnvJson() {
    return this.http
      .get("/assets/env.json")
      .toPromise()
      .then(
        (env: IEnvJson) => {
          this.envJson = env;
        },
        (error) => {
          throw error;
        }
      );
  }

  get baseAuthUrl() {
    this._checkEmptyConfig();
    return this._envConfig.BaseAuthUrl;
  }

  get baseBookingUrl() {
    this._checkEmptyConfig();
    return this._envConfig.BaseBookingUrl;
  }

  get baseClientUrl() {
    this._checkEmptyConfig();
    return this._envConfig.BaseAdminUrl;
  }

  get baseAccountUrl() {
    this._checkEmptyConfig();
    return this._envConfig.BaseProfileUrl;
  }

  get basePlasmaUrl() {
    this._checkEmptyConfig();
    return this._envConfig.BasePlasmaUrl;
  }

  get baseProfileApiUrl() {
    this._checkEmptyConfig();
    return this._envConfig.BaseProfileApiUrl;
  }

  get environment() {
    this._checkEmptyConfig();
    return this._envConfig.Environment;
  }

  get version() {
    this._checkEmptyConfig();
    return this._envConfig.Version;
  }

  get twilioEnabled() {
    this._checkEmptyConfig();
    return this._envConfig.TwilioEnabled;
  }

  get UseProfileAPI() {
    this._checkEmptyConfig();
    return this._envConfig.UseProfileAPI;
  }

  get shfyDxpBaseURL() {
    this._checkEmptyConfig();
    return this._envConfig.ShfyDxpBaseURL;
  }

  get shfyRegistrationURL() {
    this._checkEmptyConfig();
    return this._envConfig.ShfyRegistrationURL;
  }

  get shfyCompleteProfileURL() {
    this._checkEmptyConfig();
    return this._envConfig.ShfyCompleteProfileURL;
  }

  get isWardProfileEnabled() {
    this._checkEmptyConfig();
    return (this._envConfig.WardProfileEnabled || "").toLowerCase() === "true";
  }

  get isWardProfileAddEnabled() {
    this._checkEmptyConfig();

    const isWardProfileEnabled = (this._envConfig.WardProfileEnabled || "").toLowerCase() === claimValues.TRUE;
    const isWardProfileAddEnabled = (this._envConfig.WardProfileAddEnabled || "").toLowerCase() === claimValues.TRUE;
    return isWardProfileAddEnabled && isWardProfileEnabled;
  }

  get isWardProfileEditEnabled() {
    this._checkEmptyConfig();

    const isWardProfileEnabled = (this._envConfig.WardProfileEnabled || "").toLowerCase() === claimValues.TRUE;
    const isWardProfileEditEnabled = (this._envConfig.WardProfileEditEnabled || "").toLowerCase() === claimValues.TRUE;
    return isWardProfileEditEnabled && isWardProfileEnabled;
  }

  get isWardProfileDeleteEnabled() {
    this._checkEmptyConfig();

    const isWardProfileEnabled = (this._envConfig.WardProfileEnabled || "").toLowerCase() === claimValues.TRUE;
    const isWardProfileDeleteEnabled =
      (this._envConfig.WardProfileDeleteEnabled || "").toLowerCase() === claimValues.TRUE;
    return isWardProfileDeleteEnabled && isWardProfileEnabled;
  }

  get formConfig(): FormConfigInterface {
    this._checkEmptyConfig();
    return FORM_CONFIG[this._envConfig.WhiteLabelName];
  }

  private _checkEmptyConfig() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
  }

  get consentCategoryName() {
    this._checkEmptyConfig();
    return this._envConfig.ConsentCategoryName || null;
  }

  get bookingProfessionalUrl() {
    this._checkEmptyConfig();
    return this._envConfig.BookingProfessionalUrl || null;
  }
}
