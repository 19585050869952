import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { forkJoin, merge, of, throwError } from "rxjs";
import { catchError, map, mergeAll, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { queryParamKey, ACCOUNT_ACTIVATION, AUTH_METHOD } from "src/app/appsettings";
import { EAuthenticationType } from "src/app/component-modules/registerv2/register-mfa-sms/register-mfa-sms.component";
import { ApiService } from "src/app/services/api/api.service";
import { generalScopeKey, twoFactorAuthenticatorScopeKey } from "src/app/services/i18n/i18n.service";
import { RegisterActions } from "../actions/registration.actions";
import { AppState } from "../reducers";
import { IClaim } from "../reducers/registration.reducer";
import { StsSelectors } from "../selectors/sts.selectors";
import { getErrorMessage } from "src/app/utils/error.util";
import { RegisterProfileApiService } from "src/app/services/register-profile-api/register-profile-api.service";
import { RegistrationSelectors } from "../selectors/registration.selectors";
import { ConfigService } from "src/app/services/config/config.service";

const translationKey = "taraEmailNotificationComponent";

@Injectable()
export class RegistrationEffects {
  bookingUrl$ = this._store.pipe(select(StsSelectors.getBaseBookingUrl));
  plasmaUrl$ = this._store.pipe(select(StsSelectors.getBasePlasmaUrl));
  isCZTenant$ = this._store.pipe(select(StsSelectors.isCzechRepublic));
  isPatient$ = this._store.select(RegistrationSelectors.getRegistrationIsPatient);
  isForBooking$ = this._activatedRoute.queryParamMap.pipe(
    map((x) => {
      const isForBookingQuery = x.get("isForBooking");
      return typeof isForBookingQuery === "string" && isForBookingQuery.trim().toLowerCase() === "true";
    })
  );
  paramMap$ = this._activatedRoute.queryParamMap;
  userId$ = this.paramMap$.pipe(map((x) => x.get(queryParamKey.USER_ID)));

  constructor(
    private _actions$: Actions,
    private _api: ApiService,
    private _store: Store<AppState>,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _transloco: TranslocoService,
    private _toastr: ToastrService,
    private configService: ConfigService,
    private registerProfileApi: RegisterProfileApiService
  ) {}

  loadRegistrationClaims$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.loadRegistrationClaims),
        switchMap(({ id, seqId }) => {
          return this._api.get("/manage/accounts/complete_profile", {
            id,
            seqId,
            sequenceId: seqId,
          });
        }),
        tap((n) => {
          const claims = <any>this.getClaimsAsObject(n);
          this._store.dispatch(RegisterActions.loadRegistrationClaimSuccess({ claims }));
        }),
        catchError((error) => {
          this._store.dispatch(RegisterActions.loadRegistrationClaimFailed({ error }));
          return of(null);
        })
      ),
    { dispatch: false }
  );

  loadExistsRegistrationClaims$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.loadExistingUserClaims),
        switchMap((action) => this.registerProfileApi.getPtExisitngProfile(action.userid)),
        tap((n) => {
          const claims = <any>this.getClaimsAsObject(n);
          this._store.dispatch(RegisterActions.loadRegistrationClaimSuccess({ claims }));
        }),
        catchError((error) => {
          this._store.dispatch(RegisterActions.loadRegistrationClaimFailed({ error }));
          return of(null);
        })
      ),
    { dispatch: false }
  );

  loadExternalAuthProviders$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.loadExternalAuthProviders),
        switchMap(() => this._api.get("/manage/externalauthproviders")),
        tap((items) => {
          this._store.dispatch(RegisterActions.loadExternalAuthProviderSuccess({ items }));
        }),
        catchError((error) => {
          this._store.dispatch(RegisterActions.loadExternalAuthProviderFailed({ error }));
          return of(null);
        })
      ),
    { dispatch: false }
  );

  setUserPassword$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.setUserPassword),
        switchMap(({ payload, regId }) =>
          this._api.put(`/manage/accounts/set_authentication_provider?id=${regId}`, payload).pipe(map((n) => regId))
        ),
        tap((id) => {
          this.bookingUrl$.pipe(withLatestFrom(this.isPatient$, this.isCZTenant$, this.plasmaUrl$), take(1)).subscribe(([bookingUrl, isPatient, isCZ, basePlasmaUrl]) => {
            if (isCZ) {
              window.location.href = basePlasmaUrl;
            } else if (bookingUrl && isPatient) {
              window.location.href = bookingUrl;
            } else {
              this._router.navigate(["registrationv2", "success"], { queryParams: { id } });
            }
          });
        }),
        catchError((error) => {
          this._store.dispatch(RegisterActions.setUserPasswordFailed({ error }));
          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  loadTaraUserInfo$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.loadTaraUserInfo),
        switchMap(({ userId }) =>
          this._api.get(`/manage/accounts/tara-user-information/${userId}`).pipe(map((n) => n?.email))
        ),
        tap((email) => this._store.dispatch(RegisterActions.loadTaraUserInfoSuccess({ email }))),
        catchError((error) => {
          this._store.dispatch(RegisterActions.loadTaraUserInfoFailed({ error }));
          this.redirect(error);

          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  taraResendEmailActivation$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.taraResendEmailActivation),
        switchMap(({ userId }) => this._api.put(`/manage/accounts/tara-resend-invitation/${userId}`)),
        tap(() => {
          this._store.dispatch(RegisterActions.taraResendEmailActivationSuccess());

          const title = this.translate(generalScopeKey, "successTitle");
          const message = this.translate(translationKey, "resendEMailActivationLinkSuccess");

          this._toastr.success(message, title);
        }),
        catchError((error) => {
          this._store.dispatch(RegisterActions.taraResendEmailActivationFailed({ error }));
          this.redirect(error);

          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  activateUserAccount$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.activateUserAccount),
        switchMap(({ userId, accountType }) => {
          const isTelia: boolean = accountType == AUTH_METHOD.TELIA;
          const authMethod: string = isTelia ? AUTH_METHOD.TELIA : AUTH_METHOD.TARA;
          return this._api.put(`/manage/accounts/${authMethod}-activate-user/${userId}`);
        }),
        tap((value) => {
          this.navigate(ACCOUNT_ACTIVATION.EMAIL_VERIFIED);
          this._store.dispatch(RegisterActions.activateTaraUserSuccess({ activated: true }));
        }),
        catchError((error) => {
          this.navigate(ACCOUNT_ACTIVATION.VERIFICATION_FAILED);
          this._store.dispatch(RegisterActions.activateTaraUserFailed({ error }));
          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  updatePhoneNumber$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.updatePhoneNumber),
        switchMap(({ payload }) =>
          this._api.put(`/manage/accounts/change_phone`, payload).pipe(
            map((n) => ({
              phone: payload?.phone,
              result: n,
            }))
          )
        ),
        tap(({ phone }) => {
          this._store.dispatch(RegisterActions.updatePhoneNumberSuccess({ phone }));
        }),
        catchError((error) => {
          this._store.dispatch(RegisterActions.updatePhoneNumberFailed({ error }));
          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  updatePhoneNumberFailure$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.updatePhoneNumberFailed),
        tap(({ error }) => this._toastr.error(getErrorMessage(error)))
      ),
    { dispatch: false }
  );

  verifyPhoneNumber$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.verifyPhoneNumber),
        switchMap(({ payload }) => this._api.put(`/manage/accounts/verify_phone`, payload)),
        tap((success: boolean) => {
          this._store.dispatch(RegisterActions.verifyPhoneNumberSuccess({ success }));
        }),
        catchError((error) => {
          this._store.dispatch(RegisterActions.verifyPhoneNumberFailed({ error }));
          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  verifyPhoneNumberSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.verifyPhoneNumberSuccess),
        map(({ success }) => {
          if (success) {
            const m = this._transloco.translate(`${twoFactorAuthenticatorScopeKey}.mobileNumberVerified`);
            this._toastr.success(m);
          } else {
            const h = this._transloco.translate(`${generalScopeKey}.errorOccuredTitle`);
            const m = this._transloco.translate(`${twoFactorAuthenticatorScopeKey}.invalidTokenMessage`);
            this._toastr.error(m, h);
          }
        })
      ),
    { dispatch: false }
  );

  enableMFA$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.enableMFA),
        switchMap(({ payload }) =>
          this._api.put(`/manage/accounts/set_two_factor_authentication`, payload).pipe(
            map((n) => ({
              result: n,
              type: payload.twoFactorProvider,
            }))
          )
        ),
        tap((r) =>
          this._store.dispatch(
            RegisterActions.enableMFASuccess({
              data: r.result,
              authType: <any>r.type,
            })
          )
        ),
        catchError((error) => {
          this._store.dispatch(RegisterActions.enableMFAFailed({ error }));
          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  enableMFASuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.enableMFASuccess),
        map(({ data, authType }) => {
          if (authType == EAuthenticationType.AUTHENTICATOR) {
            this._store.dispatch(RegisterActions.setAuthenticatorAppData({ data }));
          }
        })
      ),
    { dispatch: false }
  );

  enableMFAVerify$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.enableMFAVerify),
        switchMap(({ payload }) =>
          this._api.put(`/manage/accounts/verify_two_factor_authentication`, payload).pipe(
            map((n: boolean) => ({
              result: n,
              type: payload.tokenProvider,
            }))
          )
        ),
        tap((data) => this._store.dispatch(RegisterActions.enableMFAVerifySuccess({ data: <any>data }))),
        catchError((error) => {
          this._store.dispatch(RegisterActions.enableMFAVerifyFailed({ error }));
          return throwError(error);
        })
      ),
    { dispatch: false }
  );

  enableMFAVerifySuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.enableMFAVerifySuccess),
        withLatestFrom(this.userId$, this.bookingUrl$),
        map(([{ data }, userid, bookingUrl]) => {
          if (data.result) {
            // show success message
            const m = this._transloco.translate(`${twoFactorAuthenticatorScopeKey}.twoFaSuccessText`);
            this._toastr.success(m);
            //redirect to booking pt-ee user
            if (userid) {
              window.location.assign(bookingUrl);
            }
          } else {
            // show error message
            const h = this._transloco.translate(`${generalScopeKey}.errorOccuredTitle`);
            const m = this._transloco.translate(`${twoFactorAuthenticatorScopeKey}.invalidTokenMessage`);

            this._toastr.error(m, h);
          }
        })
      ),
    { dispatch: false }
  );

  enableMFAVerifyFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RegisterActions.enableMFAVerifyFailed),
        map(({ error }) => {
          const message = error?.error?.ErrorMessage;
          const title = this._transloco.translate(`${generalScopeKey}.errorOccuredTitle`);
          this._toastr.error(message, title);
        })
      ),
    { dispatch: false }
  );

  getClaimsAsObject(claims: IClaim[]) {
    return claims.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue }), {});
  }

  translate(scope: string, key: string): string {
    return this._transloco.translate(`${scope}.${key}`);
  }

  redirect(e: HttpErrorResponse) {
    let activated: boolean = false;
    const message: string = e.error?.message || e.error?.ErrorMessage;
    if (message?.toLowerCase() == "user is already activated") {
      activated = true;
    }

    if (activated) {
      return this.navigate(ACCOUNT_ACTIVATION.EMAIL_ACTIVATED);
    }
    return this.navigate(ACCOUNT_ACTIVATION.VERIFICATION_FAILED);
  }

  navigate(status: ACCOUNT_ACTIVATION) {
    this._router.navigate(["registration", "account_activation"], {
      queryParams: { status },
    });
  }
}
